<template>
  <div class="home">
    <!-- <creatroom></creatroom> -->
    <boxDetail :boxDetail="boxDetail"></boxDetail>
    <div class="header_top">
      <handleback></handleback>
      <div>急速竞技</div>
      <div @click="
      handleilltate();
    playAudio();
    ">
        玩法说明
      </div>
    </div>
    <div class="space_between pk_top">
      <div></div>
      <div class="model_pk space_between">
        <div style="text-align: center; font-weight: bold; width: 100%; font-size: 20px">
          对战大厅
        </div>
        <!-- <div
          class="center"
          @click="
            handlemodel('0');
            playAudio();
          "
          :class="obj.model == '0' ? 'active' : ''"
        >
          欧皇
        </div>
        <div
          class="center"
          @click="
            handlemodel('1');
            playAudio();
          "
          :class="obj.model == '1' ? 'active' : ''"
        >
          非酋
        </div> -->
      </div>
      <div class="creat_pk center btn_click" @click="
      handleCreat();
    playAudio();
    ">
        创建房间
      </div>
      <!-- <div
        class="creat_pk center btn_click"
        @click="lotteryTest"
      >抽奖组件</div>
      <div
        class="creat_pk center btn_click"
        @click="lotterySeatTest"
      >座位组件</div> -->
    </div>
    <!-- 宝箱列表 -->
    <div class="pk_center" @scroll="boxScroll">
      <div class="center_box" v-for="(item, index) in boxList" :key="index" @click="getBoxDetail(item.boxId)">
        <div class="center_box_img">
          <img :src="item.boxImg01 | fullPath" alt width="100%" />
        </div>
        <div class="center_box_money money-money">
          <money class="money"></money>
          {{ item.price }}
        </div>
        <div class="textover-f center_box_name">{{ item.boxName }}</div>
      </div>
    </div>
    <!-- 房间状态 -->
    <div class="home_status space_around">
      <div class="status_btn center" :class="obj.status == '0' ? 'active' : ''" @click="
      handleChangestatus(0);
    playAudio();
    ">
        进行中
      </div>
      <!-- <div
        class="status_btn center"
        :class="obj.status == '2' ? 'active' : ''"
        @click="handleChangestatus(2)"
      >
        已结束
      </div> -->
      <div class="status_btn center" :class="obj.status == '3' ? 'active' : ''" @click="
      handleChangestatus(3);
    playAudio();
    ">
        我参与的
      </div>
      <!-- <div
        class="status_btn center"
        :class="obj.status == '4' ? 'active' : ''"
        @click="handleChangestatus(4)"
      >
        历史记录
      </div> -->
    </div>

    <!-- 历史记录 -->
    <div class="roomList_box" @scroll="roomScroll">
      <div class="room_list" v-if="obj.status == 3">
        <div class="room" v-for="(item, index) in myList" :key="index">
          <div class="room_top space_between">
            <div>
              <div v-if="item.status == '0'" class="room_top_status space_around">
                <svg t="1708854733700" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="4598">
                  <path
                    d="M512 0l60.416 325.973333 240.5376-228.181333-142.711467 299.246933 328.704-43.246933L707.584 512l291.362133 158.208-328.704-43.246933 142.677334 299.246933-240.469334-228.181333L512 1024l-60.416-325.973333-240.5376 228.181333 142.711467-299.246933-328.704 43.246933L316.416 512 25.053867 353.792l328.704 43.246933-142.677334-299.246933 240.469334 228.181333L512 0z"
                    fill="#25F484" p-id="4599" />
                </svg>等待中
              </div>
              <div v-if="item.status == '1'" class="room_top_status space_around">
                进行中
              </div>
              <div v-if="item.status == '2'" class="room_top_status space_around">
                <svg t="1708854386621" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" width="200">
                  <path
                    d="M512 0l60.416 325.973333 240.5376-228.181333-142.711467 299.246933 328.704-43.246933L707.584 512l291.362133 158.208-328.704-43.246933 142.677334 299.246933-240.469334-228.181333L512 1024l-60.416-325.973333-240.5376 228.181333 142.711467-299.246933-328.704 43.246933L316.416 512 25.053867 353.792l328.704 43.246933-142.677334-299.246933 240.469334 228.181333L512 0z"
                    fill="#727477" p-id="4450" />
                </svg>
                已结束
              </div>
            </div>
            <div class="room_model center">
              <div v-if="item.model == 0">欧皇</div>
              <div v-if="item.model == 1">非酋</div>
            </div>
            <div>回合数：{{ item.roundNumber }}</div>
          </div>
          <div class="room_avatar left">
            <div class="avatar_box_hive center" :class="aitem.isHouseOwner == 0 ? 'active' : '0'"
              v-for="(aitem, aindex) in item.seats" :key="aindex + 'a'">
              <img v-if="aitem.playerId" :src="aitem.avatar|fullPath" alt width="100%" height="100%" />
            </div>
            <div v-for="(pitem, pindex) in item.seats" :key="pindex + 'p'">
              <div class="avatar_box_null center" v-if="pitem.playerId == null">
                <i class="el-icon-circle-plus-outline"></i>
              </div>
            </div>
          </div>
          <!-- 宝箱 -->
          <div class="room_box space_between">
            <div class="room_box_left space_between">
              <div class="room_box_left_arms_box">
                <div class="room_box_left_arms" :style="{ left: '-' + sss + 'px' }">
                  <div class="arms center" v-for="(bitem, bindex) in item.boxData" :key="bindex + 'b'">
                    <img :src="bitem.boxImg01|fullPath" alt class="arms_img" />
                    <div class="center_number center">{{ bitem.number }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="money-money">
              <money class="money"></money>
              {{ item.boxPriceTotal }}
            </div>
          </div>

          <div class="room_btn btn_click">
            <div @click="
      handleJoin(item.id);
    playAudio();
    " class="room_btn_jiaru center" v-if="item.status == '0'">
              进入
            </div>
            <div @click="
      handleAudience(item.id);
    playAudio();
    " class="room_btn_guanzhan center" v-if="item.status == '1'">
              观战
            </div>
            <div @click="
      handleCheck(item.id);
    playAudio();
    " class="room_btn_jieshu center" v-if="item.status == '2' || item.status == '3'">
              查看
            </div>
          </div>
        </div>
      </div>
      <!-- 房间列表 -->
      <div class="room_list" v-else>
        <div class="room" v-for="(item, index) in roomList" :key="index">
          <div class="room_top space_between">
            <div>
              <div v-if="item.status == '0'" class="room_top_status space_around">
                <svg t="1708854733700" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="4598">
                  <path
                    d="M512 0l60.416 325.973333 240.5376-228.181333-142.711467 299.246933 328.704-43.246933L707.584 512l291.362133 158.208-328.704-43.246933 142.677334 299.246933-240.469334-228.181333L512 1024l-60.416-325.973333-240.5376 228.181333 142.711467-299.246933-328.704 43.246933L316.416 512 25.053867 353.792l328.704 43.246933-142.677334-299.246933 240.469334 228.181333L512 0z"
                    fill="#25F484" p-id="4599" />
                </svg>等待中
              </div>
              <div v-if="item.status == '1'" class="room_top_status space_around">
                进行中
              </div>
              <div v-if="item.status == '2'" class="room_top_status space_around">
                <svg t="1708854386621" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" width="200">
                  <path
                    d="M512 0l60.416 325.973333 240.5376-228.181333-142.711467 299.246933 328.704-43.246933L707.584 512l291.362133 158.208-328.704-43.246933 142.677334 299.246933-240.469334-228.181333L512 1024l-60.416-325.973333-240.5376 228.181333 142.711467-299.246933-328.704 43.246933L316.416 512 25.053867 353.792l328.704 43.246933-142.677334-299.246933 240.469334 228.181333L512 0z"
                    fill="#727477" p-id="4450" />
                </svg>
                已结束
              </div>
            </div>
            <div class="room_model center ouhuang" v-if="item.model == 0">
              <div>欧皇</div>
            </div>
            <div class="room_model center feiqiu" v-if="item.model == 1">
              <div>非酋</div>
            </div>
            <div>回合数：{{ item.roundNumber }}</div>
          </div>
          <div class="room_avatar left">
            <div class="center" :class="aitem.isHouseOwner == 0 ? 'active' : '0'" v-for="(aitem, aindex) in item.seats"
              :key="aindex + 'a'">
              <img v-if="aitem.playerId" :src="aitem.avatar|fullPath" alt class="avatar_box_hive" />
            </div>
            <div v-for="(pitem, pindex) in item.seats" :key="pindex + 'p'">
              <div class="avatar_box_null center" v-if="pitem.playerId == null">
                <i class="el-icon-circle-plus-outline"></i>
              </div>
            </div>
          </div>
          <!-- 宝箱 -->
          <div class="room_box space_between">
            <div class="room_box_left space_between">
              <div class="room_box_left_arms_box">
                <div class="room_box_left_arms" :style="{ left: '-' + sss + 'px' }">
                  <div class="arms center" v-for="(bitem, bindex) in item.boxData" :key="bindex + 'b'">
                    <img :key="index + 'b'" :src="bitem.boxImg01|fullPath" alt class="arms_img" />
                    <!-- &ensp;X {{ bitem.number }} -->
                    <div class="center_number center">{{ bitem.number }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="money-money">
              <money class="money"></money>
              {{ item.boxPriceTotal }}
            </div>
          </div>
          <!-- 按钮 -->
          <div class="room_btn btn_click">
            <div @click="
      handleJoin(item.id);
    playAudio();
    " class="room_btn_jiaru center" v-if="item.status == '0'">
              进入
            </div>
            <div @click="
      handleAudience(item.id);
    playAudio();
    " class="room_btn_guanzhan center" v-if="item.status == '1'">
              观战
            </div>
            <div @click="
      handleCheck(item.id);
    playAudio();
    " class="room_btn_jieshu center" v-if="item.status == '2' || item.status == '3'">
              查看
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* getFightBoxList,
  getFightList,
  getMyPartFightList, */
import {
  getFightList,
  getUserInfo,
  fightOnMyOwn,
  earlierHistory,
  getBoxList,
} from "@/api/index";
import boxDetail from "./boxDetail.vue";
import bsurl from "@/api/basurl.js";
export default {
  data() {
    return {
      page: {
        page: 1,
        size: 10,
      },
      earlierHistoryPage: {
        fightId: 0,
        page: 1,
        size: 10,
      },
      obj: {
        status: 0,
        // pageSize: 100,
        // pageNum: 1
      },
      boxList: [],
      roomList: [],
      boxTypelist: [],
      money: 0,
      sss: 1,
      timerV2: null,
      queryParams: {
        pageSize: 100,
        pageNum: 2,
      },
      hearbeat_timer: null,
      befroomlist: [],
      histroyList: [],
      myList: [],
      roomListIsOver: "加载中",
      myListIsOver: "加载中",
      boxIsOver: "加载中",
      boxPage: {
        page: 1,
        size: 10,
        gameMouldId: 2,
        orderByFie: 2,
      },
      boxDetail: {
        flag: false,
        boxId: "",
      },
      params: {
        pageNum: 1,
        pageSize: 40,
        status: 0,
      },
    };
  },
  watch: {
    roomList(newValue) {
      if (newValue.length != 0) {
        this.earlierHistoryPage.fightId = newValue[newValue.length - 1].id;
      }
    },
  },
  created() {
    this.getBox();
    this.getuser();
  },
  computed: {},
  components: {
    boxDetail,
  },
  mounted() {
    // 建立 web socket 连接
    //this.getuser();
    /* this.getList(); */
  },
  beforeDestroy() {
    this.$sockets.close();
    /*  this.$sockets.close(); */
    /*  clearInterval(this.timerV2);
    this.timerV2 = null; */
  },
  methods: {
    boxScroll(e) {
      if (
        e.srcElement.scrollLeft + e.srcElement.clientWidth >=
        e.srcElement.scrollWidth
      ) {
        if (this.boxIsOver == "加载中") {
          this.boxPage.page++;
          setTimeout(() => {
            this.getBox();
          }, 5);
        }
      }
    },
    getList() {
      getFightList().then((res) => {
        console.log("第一次数据", res);
        let arr = res.data.data;
        arr.map((item) => {
          let obj = JSON.parse(item.boxData);
          let valueList = [];
          for (let i in obj) {
            valueList.push(obj[i]);
          }
          return (item.boxData = valueList);
        });
        this.roomList = arr;
      });
    },
    roomScroll(e) {
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        if (this.obj.status == 3 && this.myListIsOver == "加载中") {
          this.page.page++;
          setTimeout(() => {
            this.getMy();
          }, 5);
        } else if (this.obj.status == 0 && this.roomListIsOver == "加载中") {
          setTimeout(() => {
            this.getEarlierHistory();
          }, 5);
        }
      }
    },
    getEarlierHistory() {
      earlierHistory(this.earlierHistoryPage).then((res) => {
        console.log("更早的房间", res.data.data);
        if (res.data.code == 200) {
          this.roomList.push(...res.data.data);
          if (res.data.data.length < this.earlierHistoryPage.size) {
            this.roomListIsOver = "暂无更多";
          }
        }
      });
    },
    playAudio() {
      this.$store.commit("playAudio");
    },
    handleCheck(id) {
      this.$router.push({
        name: "roomDetail",
        query: {
          id: id,
        },
      });
    },
    getBoxDetail(boxId) {
      console.log(boxId);
      console.log("show_box_detail");
      this.boxDetail.flag = true;
      this.boxDetail.boxId = boxId;
    },
    //观战
    handleAudience(fildid) {
      this.$router.push({
        name: "battleroom",
        query: {
          id: fildid,
          uid: this.userId,
          flag: 1,
        },
      });
    },
    //获取用户信息
    getuser() {
      var that = this;
      getUserInfo().then((res) => {
        if (res.data.code == 200) {
          that.userId = res.data.data.userId;
          that.wssock();
        }
      });
    },
    //websocket连接

    wssock() {

      let query = "";
      for (const key in this.params) {
        if (this.params[key] !== null && this.params[key] !== "") {
          if (query.length === 0) {
            query += `?${key}=${this.params[key]}`;
          } else {
            query += `&${key}=${this.params[key]}`;
          }
        }
      }
      this.$sockets = new WebSocket(
        bsurl.BASE_API + "/ws/fight/hall/" + this.userId + "? " + query
      );
      this.$sockets.onopen = () => {
        //console.log("WebSocket 连接已打开");
      };
      console.log("WebSocket 连接已打开");
      this.$sockets.onmessage = (event) => {
        console.log("收到消息：", JSON.parse(event.data));
        let data = JSON.parse(event.data);
        if (data.key) {
          let i = this.roomList.findIndex((item) => {
            if (!data.data.rows) {
              return item.id == data.data[0].id;
            } else {
              return item.id == data.data.rows[0].id;
            }

          });
          if (i == -1) {
            if (!data.data.rows) {
              if (data.data[0].status == 2 || data.data[0].status == 3) {
                return;
              } else {
                if (this.roomList.length < 10) {
                  this.roomList.unshift(...data.data);
                } else {
                  this.roomList.unshift(...data.data);
                  this.roomList.splice(
                    this.roomList.length - data.data.length,
                    data.data.length
                  );
                }
              }
            } else {


              if (data.data.rows[0].status == 2 || data.data.rows[0].status == 3) {
                return;
              } else {
                if (this.roomList.length < 10) {
                  this.roomList.unshift(...data.data.rows);
                } else {
                  this.roomList.unshift(...data.data.rows);
                  this.roomList.splice(
                    this.roomList.length - data.data.rows.length,
                    data.data.length
                  );
                }
              }
            }
          } else {
            if (data.data.rows[0].status == 2 || data.data.rows[0].status == 3) {
              this.roomList.splice(i, 1);
            } else {
              this.roomList.splice(i, 1, data.data.rows[0]);
            }
          }
        }

        // that.befroomlist = data.data;

        // if (that.befroomlist) {
        //   /*   for(let i=0;i<this.befroomlist.length;i++){
        //   this.befroomlist[i].boxData=JSON.parse(this.befroomlist[i].boxData)
        // } */
        //   this.roomList = [];
        //   that.getRoom();
        // }
      };
      this.$sockets.onclose = () => {
        console.log("WebSocket 连接已关闭");
        if (this.$route.path == "/px") {
          this.wssock();
        }
      };
    },
    //房间的类型 欧皇

    //玩法说明
    handleilltate() {
      this.$bus.$emit("is_illustrate", true);
      setTimeout(() => {
        this.$bus.$emit("nr_illustrate", "pk");
      }, 100);
    },
    //加入放进观战，查看等
    handleJoin(fildid) {
      //加入前的校验

      this.$router.push({
        name: "battleroom",
        query: {
          id: fildid,
          uid: this.userId,
          currentRound: 0,
        },
      });
    },
    //状态
    //参与进行结束
    handleChangestatus(res) {
      if (res == 3) {
        this.obj.status = res;
        this.page.page = 1;
        this.myList = [];
        this.getMy();
        this.$sockets.close();
      } else {
        this.obj.status = res;
        this.getuser();
        /*   this.getList(); */
      }
    },
    //创建房间
    handleCreat() {
      console.log(111);
      this.$router.push({
        name: "creatroom",
      });
    },
    lotteryTest() {
      // 跳转
      this.$router.push({
        name: "lottery",
        query: {},
      });
    },
    lotterySeatTest() {
      // 跳转
      this.$router.push({
        name: "lotterySeat",
        query: {},
      });
    },
    //获取数据 //我的对战
    getMy() {
      fightOnMyOwn({
        playerId: this.userId,
        page: this.page.page,
        size: this.page.size,
        statusList: [0, 1, 2, 3],
      }).then((res) => {
        let arr = res.data.data;
        arr.map((item) => {
          let obj = JSON.parse(item.boxData);
          let valueList = [];
          for (let i in obj) {
            valueList.push(obj[i]);
          }
          return (item.boxData = valueList);
        });
        console.log("fightOnMyOwn", arr);
        this.myList.push(...arr);
        if (res.data.data.length < this.page.size) {
          this.myListIsOver = "暂无更多";
        }
      });
    },
    //全部对战，查询所有对战列表

    //查询对战宝箱列表
    getBox() {
      let data={
        boxType:2
      }
      getBoxList(data).then((res) => {
        if (res.data.code == 200) {
          console.log("箱子列表", res.data.data);
          let boxData = [];
          res.data.data.forEach((item) => {
            /* console.log(...item.boxList) */
            boxData.push(...item.boxList);
          });
          this.boxList.push(...boxData);
           // 箱子列表金额从小到大排序
           this.boxList.sort((a, b) => {
            return a.price - b.price;
          });
          if (res.data.data.length < this.boxPage.size) {
            this.boxIsOver = "暂无更多";
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.center_number {
  width: 28px;
  height: 28px;
  position: absolute;
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
  background: #048a42;
  border-radius: 50%;

  @media (max-width: 550px) {
    font-size: 13px;
    width: 21px;
    height: 21px;
  }
}

.room_btn_guanzhan {
  width: 100%;
  height: 42px;
  background-image: url("../../assets/image/pk_watch.png");
  background-size: 100% 100%;
  font-size: 21px;
  margin: 0 auto;

  @media (max-width: 550px) {
    width: 355px;
    height: 30px;
    font-size: 15px;
  }
}

.roomList_box {
  width: 100%;
  margin: 0 auto;
  height: 2500px;
  overflow-y: auto;

  @media (max-width: 500px) {
    height: 1820px;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
}

.room_list {
  width: 100%;
  margin: 0 auto;

  .room {
    border: 1px solid white;
    width: 100%;
    margin: 14px auto;
    height: 238px;
    padding: 2% 2% 4% 2%;
    background: linear-gradient(211deg,
        rgba(114, 116, 119, 0.1) 0%,
        rgba(50, 52, 54, 0.05) 100%);
    border-radius: 14px 14px 14px 14px;
    opacity: 1;

    // transition: all 0.3s ease-in-out;
    @media (max-width: 500px) {
      height: 170px;
      font-size: 12px;
    }

    .room_top {
      width: 95%;
      margin: 0 auto;

      .room_top_status {
        font-size: 17px;
        width: 81px;

        @media (max-width: 550px) {
          width: 59px;
          font-size: 12px;
        }

        .icon {
          width: 21px;
          height: 21px;
          animation: infinite-spin 5s linear infinite;

          @media (max-width: 550px) {
            width: 15px;
            height: 15px;
          }
        }
      }

      .room_model {
        width: 100px;
        height: 25px;
        color: white;
        border-radius: 50px 50px 50px 50px;
      }

      .ouhuang {
        background: #f9bf1b;
      }

      .feiqiu {
        background: #3a97e4;
      }
    }
  }

  .room_avatar {
    width: 95%;
    margin: 0 auto;
    margin-top: 2%;

    .avatar_box_hive {
      width: 43px;
      height: 43px;
      opacity: 1;
      border-radius: 50%;
      margin-right: 14px;
      overflow: hidden;

      @media (max-width: 500px) {
        width: 30px;
        height: 30px;
      }
    }

    .active {
      border: 1px solid #25f484;
    }

    .avatar_box_null {
      width: 42px;
      height: 42px;
      margin-right: 14px;

      background: rgba(37, 244, 132, 0.2);
      opacity: 1;
      border: 1px solid #25f484;
      border-radius: 50%;

      @media (max-width: 500px) {
        width: 30px;
        height: 30px;
      }

      i {
        color: rgba(37, 244, 132, 1);
      }
    }
  }

  .room_box {
    width: 95%;
    margin: 0 auto;
    margin-top: 2%;
    -webkit-overflow-scrolling: touch;

    .room_box_left {
      width: 60%;

      .btn {
        width: 26px;
        height: 26px;
        background: #323436;
        opacity: 1;
        border-radius: 50%;

        @media (max-width: 500px) {
          width: 19px;
          height: 19px;
        }

        i {
          color: RGBA(26, 28, 31, 1);
          font-weight: bold;
        }
      }

      .room_box_left_arms_box {
        width: 80%;
        overflow: hidden;
        position: relative;
        height: 67px;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        /* 在移动设备上启用触摸滚动 */

        &::-webkit-scrollbar {
          height: 5px;
        }

        &::-webkit-scrollbar-track {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(50, 52, 54, 1);
          border-radius: 5px;
        }

        @media (max-width: 500px) {
          height: 45px;
        }

        .room_box_left_arms {
          // width: 500px;
          display: flex;

          // position: absolute;
          // top: 0;
          // left: 0;
          .arms {
            height: 49px;
            background: #323436;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            margin: 2px 10px 2px 2px;
            padding: 0 6px;
            position: relative;

            @media (max-width: 500px) {
              height: 35px;
            }

            .arms_img {
              width: 37px;

              @media (max-width: 550px) {
                width: 26px;
              }
            }
          }
        }
      }
    }
  }

  .room_btn {
    margin: 0 auto;
    margin-top: 2%;

    .room_btn_jiaru {
      margin: 0 auto;
      width: 100%;
      height: 42px;
      font-size: 21px;
      background: linear-gradient(360deg,
          rgba(37, 244, 132, 0.1) 0%,
          rgba(37, 244, 132, 0.05) 100%);
      border: 1px solid #25f484;

      @media (max-width: 550px) {
        // width: 355px;
        width: 95%;
        height: 30px;
        font-size: 15px;
      }
    }

    .room_btn_jieshu {
      width: 100%;
      height: 42px;
      background: linear-gradient(360deg,
          rgba(78, 78, 78, 0.1) 0%,
          rgba(78, 78, 78, 0.05) 100%);
      opacity: 1;
      border: 1px solid #4e4e4e;
    }
  }
}

.home_status {
  width: 45%;
  height: 42px;
  background: #323436;
  border-radius: 70px 70px 70px 70px;
  opacity: 1;
  margin: 2% auto;
  font-size: 14px;
  color: rgba(114, 116, 119, 1);

  @media (max-width: 500px) {
    width: 140px;
    height: 30px;
    font-size: 10px;
  }

  .status_btn {
    width: 50%;
    height: 35px;
    border-radius: 70px;
    transition: left 0.5s ease-in-out;

    @media (max-width: 500px) {
      width: 70px;
      height: 25px;
    }
  }

  .active {
    background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
    color: #fff;
  }
}

.pk_center::-webkit-scrollbar {
  height: 7px;
  background: transparent;
  overflow: hidden;
}

.pk_center::-webkit-scrollbar-thumb {
  height: 7px;
  background: rgba(37, 244, 132, 1);
  opacity: 1;
  border-radius: 7px;
}

.pk_center {
  margin-top: 14px;
  width: 100%;
  height: 152px;
  background-color: #101115;
  border-radius: 14px;
  overflow-x: scroll;
  display: flex;

  @media (max-width: 500px) {
    // width: 355px;
    font-size: 10px;
    height: 130px;
  }

  .center_box {
    width: 112px;
    height: auto;
    margin: 0 3.5px;

    @media (max-width: 500px) {
      width: 80px;
      margin: 0 2.5px;
    }

    .center_box_img {
      width: 84px;
      height: 84px;
      margin: 0 auto;

      @media (max-width: 500px) {
        width: 60px;
        height: 60px;
      }
    }

    .center_box_name {
      font-size: 14px;
      margin-top: 8px;

      @media (max-width: 500px) {
        font-size: 10px;
      }
    }

    .center_box_money {
      font-size: 14px;
      margin-top: 8px;

      @media (max-width: 500px) {
        font-size: 10px;
      }
    }
  }
}

.pk_top {
  width: 100%;

  .model_pk {
    width: 203px;
    height: 43px;
    border-radius: 70px 70px 70px 70px;
    opacity: 1;
    margin-left: 118px;

    @media (max-width: 500px) {
      width: 145px;
      height: 30px;
      font-size: 10px;
      margin-left: 74px;
    }

    div {
      width: 98px;
      height: 35px;
      border-radius: 70px 70px 70px 70px;

      @media (max-width: 500px) {
        width: 70px;
        height: 25px;
      }
    }

    .active {
      background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
      color: #fff;
    }
  }

  .creat_pk {
    width: 112px;
    height: 42px;
    background: linear-gradient(360deg,
        rgba(37, 244, 132, 0.5) 0%,
        rgba(37, 244, 132, 0.25) 100%);
    opacity: 1;
    border: 1px solid #25f484;

    @media (max-width: 500px) {
      width: 80px;
      font-size: 13px;
      height: 30px;
    }
  }
}

.header_top {
  width: 100%;
  height: 56px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {}

  div:last-child {
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    line-height: 1.5;
  }
}

.home {
  width: 95%;
  margin: 0 auto;
}

.room_detial {
  width: 100%;
  height: 84px;
  background: #101115;
  padding: 11px 18px 7px 18px;
  margin-top: 4px;
  font-size: 13px;
}

.room_detial_top {
  height: 40%;
}

.room_detial_bottom {
  height: 60%;
}

.room_detial_bottom_right {
  width: 45%;
}

.room_detial_bottom_right_img {
  width: 35px;
  height: 35px;
}

.hisimg {
  width: 40%;

  .room_detial_bottom_left {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
  }
}
</style>
